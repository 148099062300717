/* eslint-disable react/display-name */
import React, { useState, useImperativeHandle, forwardRef } from "react";

// Components

// Lib
import Drawer from "@material-ui/core/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import SidebarMenu from "../Reusable/SidebarMenu";

interface StandardLayoutProps {
  children?: React.ReactNode;
  ref: React.Ref<unknown>;
}

const StandardLayout = forwardRef(({ children, ref }: StandardLayoutProps) => {
  const [isOpenSideMenu, handleToggleSideMenu] = useState(false);

  const history = useNavigate();
  const { route_id } = useParams();
  useImperativeHandle(ref, () => ({
    handleToggleSideMenu: () => {
      handleToggleSideMenu(!isOpenSideMenu);
    },
  }));

  return (
    <div className="standardLayout">
      {/* SIDE BAR MENU */}

      {window.config.sideMenu && (
        <Drawer
          variant="temporary"
          anchor="right"
          open={isOpenSideMenu}
          onClose={() => handleToggleSideMenu(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidebarMenu menuContent={window.config.sideMenu} history={history} pageName={route_id} />
        </Drawer>
      )}

      {/* CONTENT */}

      <div className="children-wrapper">{children}</div>
    </div>
  );
});

export default StandardLayout;
