import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { importByPageList } from "../importPages";

import AddLocation from "./Pages/AddLocation";
import ChangeLocation from "./Pages/ChangeLocation";
import EditLocation from "./Pages/EditLocation";
import SelectOHT from "./Pages/SelectOHT";

import UserService from "../../../services/UserService";
import FilterOht from "./Pages/FilterOht";

const LOCATION_SELECTION = () => {
  const [t] = useTranslation("translations");

  const [address] = useState(UserService.getAttribute("savedLocationOht"));

  let title = "",
    distance = 0;

  let LocationTextView = () => <div />;

  if (address.type === "location") {
    const { city, postalcode } = address.address;
    title = city;
    distance = address.distance;

    // eslint-disable-next-line react/display-name
    LocationTextView = () => (
      <>
        <p className="ls__locationtitle">{title}</p>
        <p className="ls__locationsubtitle">
          {postalcode} +{distance}
          km
        </p>
      </>
    );
  } else if (address.type === "oht") {
    title = address.name;

    // eslint-disable-next-line react/display-name
    LocationTextView = () => (
      <>
        <p className="ls__locationtitle">{title}</p>
      </>
    );
  }

  return (
    <div className="ls__index">
      <div className="ls__locationbox">
        {address.position !== undefined ? (
          <LocationTextView />
        ) : (
          <p className="ls__locationtitle">{t("LocationSelection.location_title")}</p>
        )}
      </div>
      <Link to={`/LOCATION_SELECTION/${ChangeLocation.name}`} className="ls__link">
        {address.position !== undefined
          ? t("LocationSelection.location_link--hydrated")
          : t("LocationSelection.location_link")}
      </Link>
    </div>
  );
};

export default LOCATION_SELECTION;

export const LocationSelectionConfig = () =>
  importByPageList(LOCATION_SELECTION.name, [
    AddLocation,
    ChangeLocation,
    EditLocation,
    SelectOHT,
    FilterOht,
  ]);
