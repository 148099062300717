import React from "react";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";

// Utils
import {
    handleOverrideClassName,
    handleOverrideConfig,
    handleRedirect
  } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const PI1 = ({ 
    item,
    theme,
    history,
    pageName
}) => {
    const arrowIconImg = (item.icon_image && item.icon_image.path) || ArrowIcon;
    const arrowIconImgAlt = item.icon_image && item.icon_image.alt_text;

    return (
        <button 
            className={`pi1 ${handleOverrideClassName(item.classname)}`} 
            onClick={() => handleRedirect(item.button_action, history, item.title, pageName)}
            style={
                ({boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                    background: (handleOverrideConfig(theme.showImage, item.showImage) && item.image && item.image.path) && `url(${item.image.path})`,
                    cursor: handleOverrideConfig(theme.showIcon, item.showIcon) ? "pointer" : "unset", })
            }
        >   
            {/* Title */}

            {
                item.title
                &&
                <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
            }
            
            {/* Arrow Icon */}

            {
                handleOverrideConfig(theme.showIcon, item.showIcon)
                &&
                <img className={`icon-img ${handleOverrideClassName(item.iconClassname)}`} src={arrowIconImg} alt={arrowIconImgAlt} />
            }
        </button>
    );
}

export default PI1;
