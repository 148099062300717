import React, { useReducer, useContext, useState, createContext, useMemo } from "react";

type State = {
  configs: ConfigsType;
};

type Action1 = { type: "add_static_widgets"; payload: Array<ConfigType> };
type Action2 = { type: "add_flow_module"; payload: FlowResultType };

function reducer(state: State, action: Action1 | Action2) {
  switch (action.type) {
    case "add_static_widgets":
      return {
        configs: {
          ...state.configs,
          staticWidgets: [...action.payload, ...state.configs.staticWidgets],
        },
      };
    case "add_flow_module":
      return {
        configs: {
          ...state.configs,
          flowModule: {
            before: action.payload.before
              ? [...action.payload.before, ...state.configs.flowModule.before]
              : [...state.configs.flowModule.before],

            after: action.payload.after
              ? [...action.payload.after, ...state.configs.flowModule.after]
              : [...state.configs.flowModule.after],
          },
        },
      };
    default:
      throw new Error();
  }
}

export type ImportedComponentType = React.FC;
export interface ConfigType {
  name?: string;
  buttonStyle?: string;
  triggerDelay?: string;
  classname?: string;
  fullscreen?: boolean;
  before_id?: string;
  after_id?: string;
  components: Array<{ Component: ImportedComponentType }>;
}

type FlowResultType = {
  before: Array<ConfigType>;
  after: Array<ConfigType>;
};

export interface ConfigsType {
  staticWidgets: Array<ConfigType>;
  flowModule: FlowResultType;
}

type AddConfigType = "FlowModule" | "StaticWidgets";

interface AppCtxDefaultValueProps {
  isAppReady: boolean;
  setIsAppReady: (isAppReady: boolean) => void;

  hasPageFullyLoaded: boolean;
  setHasPageFullyLoaded: (hasPageFullyLoaded: boolean) => void;

  isAnalyticsInitialized: boolean;
  setIsAnalyticsInitialized: (isAnalyticsInitialized: boolean) => void;

  configs: ConfigsType;
  addConfig: (config_type: AddConfigType, payload: ConfigPayloads[AddConfigType]) => void;

  ohtFilter: string | undefined;
  setOhtFilter: (ohtFilter: string) => void;

  isOnboardingDisabled: boolean | undefined;
  setIsOnboardingDisabled: (isOnboardingDisabled: boolean) => void;
}

const appCtxDefaultValue = {
  isAppReady: false,
  setIsAppReady: () => { /* This is intentional, using fn body to build type */ },

  hasPageFullyLoaded: false,
  setHasPageFullyLoaded: () => { /* This is intentional, using fn body to build type */ },

  isAnalyticsInitialized: false,
  setIsAnalyticsInitialized: () => { /* This is intentional, using fn body to build type */ },

  configs: {
    staticWidgets: [],
    flowModule: {
      before: [],
      after: [],
    },
  },
  addConfig: () => { /* This is intentional, using fn body to build type */ },

  ohtFilter: "",
  setOhtFilter: () => {},

  isOnboardingDisabled: undefined,
  setIsOnboardingDisabled: () => {},
};

const AppContext = createContext<AppCtxDefaultValueProps>(appCtxDefaultValue);

function useApp() {
  return useContext(AppContext);
}

interface AppProviderProps {
  children: React.ReactNode;
}

interface ConfigPayloads {
  FlowModule: FlowResultType;
  StaticWidgets: Array<ConfigType>;
}

const AppProvider = (props: AppProviderProps) => {
  const [isAppReady, setIsAppReady] = useState(false);
  const [hasPageFullyLoaded, setHasPageFullyLoaded] = useState(false);
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [ohtFilter, setOhtFilter] = useState(appCtxDefaultValue.ohtFilter);
  const [isOnboardingDisabled, setIsOnboardingDisabled] = useState(undefined);

  // Memoized state management, used to reduce unnecessary state updates
  const [{ configs }, dispatch] = useReducer(reducer, {
    configs: {
      flowModule: { before: [], after: [] },
      staticWidgets: [],
    },
  } as State);

  const addConfig = <T extends AddConfigType>(configType: T, payload: ConfigPayloads[T]) => {
    switch (configType) {
      case "StaticWidgets":
        dispatch({
          type: "add_static_widgets",
          payload: payload as ConfigPayloads["StaticWidgets"],
        });
        break;
      case "FlowModule":
        dispatch({ type: "add_flow_module", payload: payload as ConfigPayloads["FlowModule"] });
        break;
      default:
        throw new Error(`Invalid configType: ${configType}`);
    }
  };

  const providerValue = useMemo(
    () => ({
      isAppReady,
      setIsAppReady,
      isAnalyticsInitialized,
      setIsAnalyticsInitialized,
      configs,
      addConfig,
      ohtFilter,
      setOhtFilter,
      hasPageFullyLoaded,
      setHasPageFullyLoaded,
      isOnboardingDisabled,
      setIsOnboardingDisabled
    }),
    [
      isAppReady,
      setIsAppReady,
      isAnalyticsInitialized,
      setIsAnalyticsInitialized,
      configs,
      ohtFilter,
      setOhtFilter,
      hasPageFullyLoaded,
      setHasPageFullyLoaded,
      isOnboardingDisabled,
      setIsOnboardingDisabled
    ]
  );

  return <AppContext.Provider value={providerValue} {...props} />;
};
export { AppProvider, useApp };
