import React, { useReducer, useContext, createContext, useMemo, useEffect } from "react";
import { useAddCompletedOnboardingFlow } from "../components/OnboardingModules";

type State = {
  currentStep: number;
  totalSteps: number;
  isOpen: boolean;
};

type Action =
  | { type: "SET_TOTAL_STEPS"; payload: number }
  | { type: "SET_OPEN"; payload: boolean }
  | { type: "NEXT_STEP" }
  | { type: "PREV_STEP" }
  | { type: "RESET_STEP" };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_TOTAL_STEPS":
      return {
        ...state,
        totalSteps: action.payload,
      };
    case "SET_OPEN":
      return {
        ...state,
        isOpen: action.payload,
      };
    case "NEXT_STEP":
      const isOpen = state.currentStep < state.totalSteps;
      return {
        ...state,
        isOpen: isOpen,
        currentStep: Math.min(state.currentStep + 1, state.totalSteps),
      };
    case "PREV_STEP":
      return {
        ...state,
        currentStep: Math.max(state.currentStep - 1, 1),
      };
    case "RESET_STEP":
      return {
        ...state,
        currentStep: 1,
      };
    default:
      throw new Error();
  }
}

interface ModalCtxDefaultValueProps {
  state: State;
  loadModal: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  resetStep: () => void;
  closeModal: () => void;
  openModal: () => void;
}

const modalCtxDefaultValue: ModalCtxDefaultValueProps = {
  state: { currentStep: 1, totalSteps: undefined, isOpen: false },
  loadModal: () => {
    // This is intentional, using fn body to build type
  },
  nextStep: () => {
    // This is intentional, using fn body to build type
  },
  prevStep: () => {
    // This is intentional, using fn body to build type
  },
  resetStep: () => {
    // This is intentional, using fn body to build type
  },
  closeModal: () => {
    // This is intentional, using fn body to build type
  },
  openModal: () => {
    // This is intentional, using fn body to build type
  },
};

const ModalContext = createContext<ModalCtxDefaultValueProps>(modalCtxDefaultValue);

function useModal() {
  return useContext(ModalContext);
}

interface ModalProviderProps {
  children: React.ReactNode;
}

const ModalProvider: React.FC<ModalProviderProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, modalCtxDefaultValue.state);
  const addFlow = useAddCompletedOnboardingFlow();

  useEffect(() => {
    if (state.currentStep === state.totalSteps) {
      addFlow("after");
    }
  }, [state.currentStep, state.totalSteps, addFlow]);

  const loadModal = (total_steps: number) => {
    dispatch({ type: "SET_TOTAL_STEPS", payload: total_steps });
  };
  const openModal = () => {
    dispatch({ type: "SET_OPEN", payload: true });
  };
  const closeModal = () => {
    dispatch({ type: "SET_OPEN", payload: false });
    dispatch({ type: "SET_TOTAL_STEPS", payload: undefined });
    dispatch({ type: "RESET_STEP" });
    addFlow("after");
  };

  const nextStep = () => {
    dispatch({ type: "NEXT_STEP" });
  };

  const prevStep = () => {
    dispatch({ type: "PREV_STEP" });
  };

  const resetStep = () => {
    dispatch({ type: "RESET_STEP" });
  };

  const providerValue = useMemo(
    () => ({
      state,
      loadModal,
      nextStep,
      prevStep,
      resetStep,
      closeModal,
      openModal,
    }),
    [state, loadModal, nextStep, prevStep, resetStep, closeModal, openModal]
  );

  return <ModalContext.Provider value={providerValue} {...props} />;
};

export { ModalProvider, useModal };
