// Images
import { useEffect, useRef } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/icon_right_arrow.svg";
import { ReactComponent as NewTwitterIcon } from "../../assets/logo.svg";

// Utils
import {
  handleOverrideClassName,
  handleOverrideConfig,
  handleRedirect,
} from "../../utils/CommonFunctions";
import { decodeHTMLEntities } from "../../services/AccessibleTextService";

// Services
import i18n from "../../i18n";

const Twitter = ({ item, theme, history, pageName }) => {
  const rootRef = useRef(null);

  useEffect(() => {
    function adjustLineClamp() {
      // console.log("adjustLineClamp");

      if (!rootRef.current) return;

      const clampTextElements = rootRef.current.querySelectorAll(".content-section .bodyText");

      clampTextElements.forEach((element) => {
        const fontSize = parseFloat(getComputedStyle(element).fontSize);
        const calculatedLineHeight =
          parseFloat(getComputedStyle(element).lineHeight) || fontSize * 1.2;

        const container = element.parentElement;
        const twitterAccountHeight = container.querySelector(".twitter-account").offsetHeight;
        const actionBtnHeight = container.querySelector(".action-btn").offsetHeight;

        const spacing_offset = 25;
        const availableHeight =
          container.offsetHeight - twitterAccountHeight - actionBtnHeight - spacing_offset;
        const numOfLines = Math.floor(availableHeight / calculatedLineHeight);

        element.style.webkitLineClamp = Math.max(2, numOfLines).toString();
      });
    }
    adjustLineClamp();

    // Not sure about your dependencies for useEffect, but here are the original ones
  }, [item, theme, history, pageName]);

  return (
    <div
      ref={rootRef}
      className={`twitter-component ${handleOverrideClassName(item.classname)}`}
      style={{
        boxShadow:
          handleOverrideConfig(theme.dropShadow, item.dropShadow) &&
          "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)",
      }}
    >
      <div className="content-section">
        <div className="twitter-account">
          {/* Twitter Logo */}
          <NewTwitterIcon
            className={`logo-img-icon ${handleOverrideClassName(item.logoImageClassname)}`}
          />

          {/* Feedsource */}
          {item.feedSource && (
            <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>
              {i18n.t(item.feedSource)}
            </h3>
          )}
        </div>

        {/* Title text */}
        {item.title && (
          <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>
            {i18n.t(decodeHTMLEntities(item.title))}
          </p>
        )}

        <button
          className="action-btn"
          tabIndex="0"
          onClick={() =>
            handleRedirect(item.action, history, item.title, pageName, item.description)
          }
        >
          {i18n.t("Twitter.see_more_button")}
          <ArrowIcon />
        </button>
      </div>

      {item.imageURL && (
        <div
          className="img-card"
          style={{ background: item.imageURL && `url(${item.imageURL})` }}
        />
      )}
    </div>
  );
};

export default Twitter;
