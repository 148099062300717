import { ConfigType } from "../../context/AppContext";

export function importAllPages(indexComponent: any, callback: any) {
  function importAll(r: any) {
    return r.keys().map(r);
  }

  const ImportStaticWidgetComponents: Array<any> = importAll(callback);

  const config: ConfigType = {
    name: indexComponent.name,
    components: ImportStaticWidgetComponents.map((importedComponent) => ({
      Component: importedComponent.default
    }))
  };

  return config;
}

export function importByPageList(name: string, pageComponentList: Array<any>) {
  const config: ConfigType = {
    name: name,
    components: pageComponentList.map((importedComponent) => ({
      Component: importedComponent
    }))
  };

  return config;
}
