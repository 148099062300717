import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Setup from "./Setup";

// Css
import "./styles/App.css";

ReactDOM.render(
  <BrowserRouter>
    <Setup />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
