import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Dispatch, useState } from "react";
import Icon, { IconEnum } from "../../../../Reusable/Icon";
import { UserInterests } from "../../Types";
import { InterestActions } from "./reducer";
import SubInterestCard from "./SubInterestCard";

interface InterestCardProps extends UserInterests {
  interests: Array<UserInterests>,
  allInterests?: Array<UserInterests>,
  dispatch?: Dispatch<InterestActions>,
}

const findAllExistingSubinterestsInCategory = (currentCategoryId: string, interests: UserInterests[]) => interests.find((Category) => Category.id === currentCategoryId).subcategories;

const InterestCard = ({
  interests, dispatch, allInterests, ...user_interests
}: InterestCardProps) => {
  const {
    icon, icon_fill, id, subcategories, title
  } = user_interests;

  const [isSelected, setIsSelected] = useState(interests.some((item) => item.id === id));
  const [isCollapsed, setIsCollapsed] = useState(!isSelected);

  const sideVariants = {
    closed: {
      transition: {
        staggerDirection: -1
      }
    },
    open: {
      transition: {
        staggerDirection: 1
      }
    }
  };

  const handleCollapseIfSubChildrenSelected = () => {
    if (findAllExistingSubinterestsInCategory(id, interests).length > 0) {
      setIsSelected(true);
    } else {
      dispatch({ type: "remove_category", category_id: id });
      setIsSelected(false);
    }
  };

  return (
    <>
      <div
        className={`interestcard ${!isCollapsed ? "interestcard--selected" : ""}`}
        onClick={() => {
          if (isSelected){
            handleCollapseIfSubChildrenSelected();
          } else {
            dispatch({
              type: "add_category",
              category: {
                title, icon, icon_fill, id, subcategories: []
              }
            });
            setIsSelected(true);
          }

          setIsCollapsed(!isCollapsed);
        }}
      >
        <div className="interestcard__container">
          <Icon iconName={icon as IconEnum} stroke={icon_fill} />
          <p className="interestcard__name">{title}</p>
        </div>
      </div>
      <AnimatePresence>
        {!isCollapsed && (
        <motion.div
          initial={{ maxHeight: 0 }}
          animate={{
            maxHeight: 400,
            transition: { delay: 0, duration: 0.5 }
          }}
          exit={{
            maxHeight: 0,
            transition: { delay: 0, duration: 0.7 }
          }}
        >
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            layout
            variants={sideVariants}
            className="subinterests"
          >
            {subcategories.map((props) => (
              <SubInterestCard
                {...props}
                key={props.id}
                interests={interests}
                dispatch={dispatch}
                currentCategory={{
                  title, id, icon, icon_fill, subcategories: findAllExistingSubinterestsInCategory(id, interests)
                }}
              />
            ))}
          </motion.div>
        </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default InterestCard;
