import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import parseNestedUrl from "../utils/parseNestedUrl";
import { handleJsBridgeForAppVersion } from "../utils/CommonFunctions";
import { useApp } from "../context/AppContext";

export function useOnboardingRedirect(configs: any) {
  const history = useNavigate();
  const { setIsOnboardingDisabled, isAppReady } = useApp();

  //  Call the Js Bridge on Component Load
  useEffect(() => {
    const fetchAppVersion = async () => {
      const versionObj: any = await handleJsBridgeForAppVersion();
      const onboardingDisabled =
      versionObj &&
      Object.keys(versionObj)?.length !== 0 &&
      window?.config?.disable_web_onboarding.includes(versionObj?.version);

      if (configs.flowModule.before.length > 0 && !onboardingDisabled) {
        handleOnboardingRedirect(configs, history);
      }
      setIsOnboardingDisabled(onboardingDisabled);
    };

    if (isAppReady) {
      fetchAppVersion();
    }
  }, [configs.flowModule, isAppReady]);
}

function handleOnboardingRedirect(configs: any, history: any) {
  const { completedFlows } = UserService.getAttribute("userPreferences");
  const basePathname = parseNestedUrl(window.location.pathname).basePathname || "homepage";

  const matchingOnboardingPage = configs.flowModule.before.find(
    (module) => module.name === basePathname
  );

  if (!matchingOnboardingPage) {
    return;
  }

  const flowCompleted = completedFlows?.find(({ pageName }) => pageName === basePathname);

  const shouldRedirectFlow =
    !flowCompleted?.before ||
    (flowCompleted?.before === true &&
      flowCompleted?.before_id !== matchingOnboardingPage?.before_id);

  if (shouldRedirectFlow) {
    const redirectBase =
      matchingOnboardingPage.name === "homepage" ? "/homepage" : `/${matchingOnboardingPage.name}`;
    history(`${redirectBase}/0`, { replace: true });
  }
}

export default useOnboardingRedirect;
