import React from "react";
import ModalWrapper from "../components/ModalWrapper";
import { ConfigType } from "../context/AppContext";
import { useModal } from "../context/ModalContext";
import UserService from "./UserService";
import parseNestedUrl from "../utils/parseNestedUrl";

class ModalCreationService {
  static createModals(configs: Array<ConfigType>) {
    return configs
      .filter(({ name, after_id }) => {
        const { basePathname } = parseNestedUrl(window.location.pathname);
        return name === basePathname && shouldOpenModal(name, after_id);
      })
      .map((config) => (
        <ModalWrapper
          key={config.name}
          triggerDelay={parseInt(config.triggerDelay, 10)}
          fullscreen={config.fullscreen}
          classname={config.classname}
          length={config.components.length}
        >
          <MultiStepModal components={config.components} />
        </ModalWrapper>
      ));
  }
}

interface MultiStepModalProps {
  components: Array<{ Component: React.ComponentType }>;
}

const MultiStepModal: React.FC<MultiStepModalProps> = ({ components }) => {
  const {
    state: { currentStep },
  } = useModal();

  const CurrentComponent = components[currentStep - 1]?.Component;

  return <div>{CurrentComponent && <CurrentComponent />}</div>;
};

function normalizePath(path: string): string {
  return path.endsWith("/") ? path.slice(0, -1) : path;
}

function shouldOpenModal(configName: string, id: string): boolean {
  const currentPath = window.location.pathname;

  const { completedFlows } = UserService.getAttribute("userPreferences");
  const foundCompletedFlow = completedFlows?.find(({ pageName }) => pageName === configName);

  if (foundCompletedFlow && foundCompletedFlow.after === true && foundCompletedFlow.after_id === id)
    return false;

  if (configName === "homepage" && currentPath === "/") return true;

  // Normalize paths
  const normalizedCurrentPath = normalizePath(currentPath);
  const normalizedConfigPath = normalizePath(`/${configName}`);

  // Check if the normalizedConfigPath is the last segment of normalizedCurrentPath
  return normalizedCurrentPath.endsWith(normalizedConfigPath);
}

export default ModalCreationService;
