/**
 * Parses a given pathname to extract the base pathname and, if present, the current page number.
 * 
 * @param {string} pathname - The URL pathname to be parsed.
 * 
 * @returns {Object} An object containing:
 * - `basePathname`: The base part of the pathname. For the root path, it returns "homepage".
 * - `currentPageNumber` (optional): The page number if present in a nested URL.
 * 
 * @example
 * parseUrl("/category/5") // Returns { basePathname: "category", currentPageNumber: 5 }
 * parseUrl("/category/")  // Returns { basePathname: "category" }
 * parseUrl("/")           // Returns { basePathname: "homepage" }
 */
export const parseNestedUrl = (pathname: string) => {
    // Normalize the pathname
    let normalizedPathname = pathname;
    if (normalizedPathname.startsWith("/")) {
        normalizedPathname = normalizedPathname.slice(1);
    }
    if (normalizedPathname.endsWith("/")) {
        normalizedPathname = normalizedPathname.slice(0, -1);
    }

    // Split the pathname into segments
    const urlSegments = normalizedPathname.split("/");

    // Handle the root path
    if (pathname === "/") {
        return { basePathname: "homepage" };
    }

    // Handle nested URLs
    if (urlSegments.length > 1) {
        const currentPageNumber = parseInt(urlSegments.pop() || "", 10);
        const basePathname = urlSegments.pop() || "";
        return { basePathname, currentPageNumber };
    }

    // Handle non-nested URLs
    const basePathname = urlSegments.pop() || normalizedPathname;
    return { basePathname };
};

export default parseNestedUrl;