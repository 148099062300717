import React from "react";

// Services
import i18n from "../../i18n";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";

// Utils
import {
    handleOverrideClassName,
     handleOverrideConfig,
     handleRedirect,
} from "../../utils/CommonFunctions";

const FC1 = ({
    item,
    theme,
    history,
    pageName
}) => {
    const arrowIconImg = (item.icon_image && item.icon_image.path) || ArrowIcon;
    const arrowIconImgAlt = item.icon_image && item.icon_image.alt_text;

    return (
        <button 
            className={`fc1 ${handleOverrideClassName(item.classname)}`} 
            onClick={() => handleRedirect(item.button_action, history, item.title, pageName)}
            style={
                ({background: (handleOverrideConfig(theme.showImage, item.showImage) && item.image && item.image.path) && `url(${item.image.path})`,
                    boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)", 
                    minHeight: !handleOverrideConfig(theme.showImage, item.showImage) && "auto",
                    cursor: handleOverrideConfig(theme.showIcon, item.showIcon) ? "pointer" : "unset", })
            }
        >   

            <div className={`content-section ${handleOverrideClassName(item.contentSectionClassname)}`}>
                {/* Caption Text */}

                {
                    item.caption
                    &&
                    <div className={`caption ${handleOverrideClassName(item.captionClassname)}`}>{i18n.t(item.caption)}</div>
                }

                <div className="wrapper">


                    {/* Title text */}

                    {
                        item.title
                        &&
                        <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
                    }

                    {/* Arrow Icon */}
                    
                    {
                        (handleOverrideConfig(theme.showIcon, item.showIcon))
                        &&
                        <img className={`icon-img ${handleOverrideClassName(item.iconClassname)}`} src={arrowIconImg} alt={arrowIconImgAlt} />
                    }
                </div>
                
            </div>
        </button>
    );
}

export default FC1;
