import React from "react";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";

// Utils
import {
    handleOverrideClassName,
    handleOverrideConfig,
    handleRedirect,
  } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const IC1 = ({ 
    item,
    theme,
    history,
    pageName
}) => {

    const arrowIconImg = (item.icon_image && item.icon_image.path) || ArrowIcon;
    const arrowIconImgAlt = item.icon_image && item.icon_image.alt_text;
    
    return (
        <button 
            className={`ic1 ${handleOverrideClassName(item.classname)}`} 
            style={
                ({boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                    flexDirection: item.imagePosition === "LEFT" ? "row-reverse" : "row", })
            }
            onClick={() => handleRedirect(item.button_action, history, item.title, pageName)}
        >   
        
            {
                handleOverrideConfig(theme.showImage, item.showImage)
                &&
                <div 
                    className={`img-card ${handleOverrideClassName(item.imgClassname)}`}  
                    style={
                        ({background: (item.image && item.image.path) && `url(${item.image.path})`, })
                    } 
                />
            }

            <div 
                className={ handleOverrideConfig(theme.showImage, item.showImage) ? `content-container ${handleOverrideClassName(item.contentContainerClassname)}` : `content-container content-container--without-img ${handleOverrideClassName(item.contentContainerClassname)}` } 
                style={{ alignItems: handleOverrideConfig(theme.contentAlignment, item.contentAlignment) === "center" ? "center" : handleOverrideConfig(theme.contentAlignment, item.contentAlignment) === "right" ?  "flex-end" : "flex-start"  }}
            >
                <div className="title-wrapper">
                    {
                        item.title
                        &&
                        <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
                    }
                    
                    {/* Arrow icon */}
        
                    {
                        handleOverrideConfig(theme.showIcon, item.showIcon)
                        &&
                        <img 
                            className={`icon-img ${handleOverrideClassName(item.iconClassname)}`} 
                            style={{ alignSelf: handleOverrideConfig(theme.iconAlignment, item.iconAlignment) === "top" ? "flex-start" : handleOverrideConfig(theme.iconAlignment, item.iconAlignment) === "bottom" ?  "flex-end" : "center"  }}
                            src={arrowIconImg} 
                            alt={arrowIconImgAlt} 
                        />
                    }
                </div>

                {
                    item.description
                    &&
                    <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>{i18n.t(item.description)}</p>
                }

            </div>


        </button>
    );
}

export default IC1;
