import { useTranslation } from "react-i18next";
import StepProgressBar from "./MTN/Components/StepProgressBar";
import Button from "../Reusable/Button";

interface BottomBarProps {
  onNext?: () => void;
  onBack?: () => void;
  stepProgress?: number;
  maxSteps?: number;
  buttonStyle?: string;
}

const BottomBar = ({ onNext, onBack, stepProgress, maxSteps, buttonStyle }: BottomBarProps) => {
  const { t } = useTranslation();

  const renderButton = (onClick, textKey, className = "") => (
    <Button
      buttonType={buttonStyle}
      buttonClassname={className}
      buttonText={t(textKey)}
      handleRedirect={onClick}
      showButtonIcon
      buttonIconImage={undefined}
      buttonIconPosition={textKey}
      buttonIconClassname={undefined}
    />
  );

  return (
    <div className="onboarding__bottom_bar">
      {stepProgress !== undefined && <StepProgressBar step={stepProgress} max_steps={maxSteps} />}
      <div className="onboarding__nav_buttons">
        {onBack && renderButton(onBack, "Onboarding.Back", "back-button")}
        {onNext && renderButton(onNext, "Onboarding.Next", "next-button")}
      </div>
    </div>
  );
};

export default BottomBar;
