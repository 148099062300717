import React from "react";
import { useNavigate } from "react-router-dom";
import { importByPageList } from "../importPages";

// Component
import SearchBarSection from "./Components/SearchBarSection";
import SearchResults from "./Pages/SearchResults";
import SearchFiltering from "./Pages/SearchFiltering";

// Hooks
import { useDidUpdateEffect } from "../../../hooks/useUpdateEffect";

// Context
import { useFetchSearchProps } from "./context/SearchWidgetContext";

// Services
import { ShowBottomBar, ShowTopBar } from "../../../services/ToggleNativeBar";

const SEARCH_BAR = (props: any) => {
  const history = useNavigate();
  const { setSearchWidgetConfig, setQueryString, setSearchResults } = useFetchSearchProps();

  // Add the config to the context to use it on pages
  useDidUpdateEffect(() => {
    if (Object.keys(props?.item).length !== 0) {
      // Save in localstorage
      // Add to context api
      localStorage.setItem("search_widget_props", JSON.stringify(props.item));
      setSearchWidgetConfig(props.item);
    }
  }, [props.item]);

  const handleRedirectToSearchRoute = () => {
    // Clear Previous Search history
    setQueryString("");
    setSearchResults([]);
    ShowTopBar(true);
    ShowBottomBar(false);

    return history(`/SEARCH_BAR/${SearchResults.name}`);
  };

  return (
    <SearchBarSection
      handleOnClick={() => handleRedirectToSearchRoute()}
      showFilter={false}
    />
  );
}

export default SEARCH_BAR;

export const SearchBarConfig = () => importByPageList(SEARCH_BAR.name, [
  SearchResults,
  SearchFiltering
]);
