import React, { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import Spinner from "./components/Reusable/Spinner";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";
import { AppProvider } from "./context/AppContext";
import { SearchWidgetProvider } from "./components/StaticWidgets/SEARCH_BAR/context/SearchWidgetContext";
import { ModalProvider } from "./context/ModalContext";

interface SetupProps {
  children?: React.ReactNode;
}

const appHeight = (size: number) => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${size}px`);
};
// window.addEventListener("resize", appHeight);

// disables all console.logs in production
// eslint-disable-next-line
process.env.NODE_ENV === "production" && (console.log = () => {});

const Setup = ({ children }: SetupProps) => {
  // console.log(localStorage);
  const beforeActive = localStorage?.userPreferences
    ? !JSON.parse(localStorage?.userPreferences)?.completedFlows?.[0]?.before
    : true;

  // const beforeActive = JSON.parse(localStorage?.userPreferences)?.completedFlows?.[0]?.before;
  // console.log(beforeActive);
  // console.log(JSON.parse(localStorage.userPreferences).completedFlows?.[0]?.before);

  useEffect(() => {
    const handleResize = () => {
      // const newSize = beforeActive ? window.outerHeight : window.innerHeight;
      const newSize = window.innerHeight;
      // console.log("outerHeight: ", beforeActive);
      appHeight(newSize);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [beforeActive]);

  return (
    <Suspense fallback={<Spinner />}>
      <ErrorBoundary FallbackComponent={({ error }) => <ErrorHandler error={error} />}>
        <AppProvider>
          <ModalProvider>
            <SearchWidgetProvider>
              <App />
            </SearchWidgetProvider>
          </ModalProvider>
        </AppProvider>
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Setup;
