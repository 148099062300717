import React from "react";

interface StepProgressBarProps {
  step: number;
  max_steps?: number;
}

/**
 *
 * @param max_steps the amount of steps in your progress flow
 * @param step the current step you want highlighted
 * @returns
 */
const StepProgressBar = ({ max_steps = 4, step }: StepProgressBarProps) => (
  <div className="progressBar">
    {Array(max_steps)
      .fill(<div />)
      .map((_, index) => (
        <StepProgressItem key={index + 1} highlighted={step === index + 1} />
      ))}
  </div>
);

interface StepProgressItemProps {
  highlighted?: boolean;
}

const StepProgressItem = ({ highlighted = false }: StepProgressItemProps) => {
  const handleStyling = highlighted ? "icon-dot--highlighted" : "";

  return <span className={`icon-dot ${handleStyling}`} />;
};

export default StepProgressBar;
