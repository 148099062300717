import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "./Modal";
import { useModal } from "../context/ModalContext";

interface ModalWrapperProps {
  triggerDelay?: number;
  children: React.ReactNode;
  fullscreen?: boolean;
  classname?: string;
  length?: number;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  triggerDelay,
  children,
  classname,
  fullscreen,
  length,
}) => {
  const location = useLocation();
  const { state, loadModal, openModal, closeModal } = useModal();

  useEffect(() => {
    loadModal(length);
  }, []);

  useEffect(() => {
    if (triggerDelay) {
      const timerId = setTimeout(() => {
        openModal();
      }, triggerDelay);

      // Cleanup the timer on component unmount
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [triggerDelay]);

  // useEffect(() => {
  //   if (state.totalSteps) openModal();
  // }, [state.totalSteps]);

  return (
    <Modal
      open={state.isOpen}
      onClose={() => {
        closeModal();
      }}
      fullscreen={fullscreen}
      classname={classname}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
