import Row from "./DynamicComponents/Row";

interface PageContentProps {
  json: any;
  pageName: string;
  theme: any;
}

const PageContent = ({ json, pageName, theme }: PageContentProps) => (
  <div className={json?.classname || "page-row"}>
    {json?.content_items?.map((item, i) => (
      <Row key={i} pageContent={item} theme={theme} pageName={pageName} />
    ))}
  </div>
);

export default PageContent;
