import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserService from "../../../../services/UserService";
import OhtSelectionCard from "../Components/OhtSelectionCard";
import FilterOht from "../Pages/FilterOht";
import { useApp } from "../../../../context/AppContext";

interface OhtManagerProps {
    items: Array<any>,
    setItems: React.Dispatch<React.SetStateAction<Array<any>>>,
}

const OhtManager = ({ items, setItems }:OhtManagerProps) => {
  const [t] = useTranslation("translations");
  const {ohtFilter} = useApp();

  const [locations, setLocations] = useState<Array<any>>(
    UserService.getAttribute("locations")
  );

  useEffect(() => {
    setTimeout(() => {

      setLocations(UserService.getAttribute("locations"))
    }, 1000)
  }, [])

  return (
    <div className="ls__ohts">
      {locations.length > 0 ? (
        <div className="ls__ohts__container">
          <p className="ls__pageheader">{t("LocationSelection.SelectOHT.TeamsNearYou")}</p>
          <div className="ls__ohts__grid">
            {window.config.ohtsNearYou.map((props) => <OhtSelectionCard {...props} type="oht" key={props.id} checkList={items} setCheckList={setItems} />)}
          </div>
        </div>
      ) : (
        <div className="ls__ohts__container">
          <p className="ls__pageheader">{t("LocationSelection.SelectOHT.TeamsNearYou")}</p>
          <p>{t("LocationSelection.SelectOHT.no_location_saved")}</p>
        </div>
      )}

      <div className="ls__ohts__container">
        <div className="ls__pageheader_container">
          <p className="ls__pageheader">{t("LocationSelection.SelectOHT.AllOntarioHealthTeams")}</p>
          <Link className="ls__link" to={`/LOCATION_SELECTION/${FilterOht.name}`}>
            
            {t("LocationSelection.SelectOHT.filter")}
          </Link>
        </div>
        <div className="ls__ohts__grid">
          {window.config.ohts
            .filter((item) => {
              if (ohtFilter === "" || ohtFilter === "all_regions") {
                return item
              }
              return item.region === ohtFilter
            })
            .map((props) => <OhtSelectionCard {...props} type="oht" key={props.id} checkList={items} setCheckList={setItems} />)}
        </div>
      </div>
    </div>
  );
};

export default OhtManager;
