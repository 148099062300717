import React from "react";

// Lib
import parse from "html-react-parser";
import { unescape } from "lodash";
// Components
import Button from "../Reusable/Button";
import ModalWrapper from "../ModalWrapper";
import { useModal } from "../../context/ModalContext";
import { useAddCompletedOnboardingFlow } from "../OnboardingModules";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";
// Utils
import {
  handleOverrideClassName,
  handleOverrideConfig,
  handleRedirect,
  sendAnalytics,
  postOnboardingActions
} from "../../utils/CommonFunctions";

// When custom formatter is selected for title and description
const FormattedText = ({ component: Component = "span", className, ariaLabel, children }) => {
  if (!children) return null;
  const decodedChildren = unescape(children);

  // Check if children contain HTML tags
  const containsHtml = /<[^>]*>/i.test(decodedChildren);

  return containsHtml ? (
    parse(decodedChildren)
  ) : (
    <Component className={className} aria-label={ariaLabel}>
      {decodedChildren}
    </Component>
  );
};

const PI2 = ({ item, theme, history, pageName }) => {
  const { openModal } = useModal();
  const addFlow = useAddCompletedOnboardingFlow();
  const arrowIconImg = (item.icon_image && item.icon_image.path) || ArrowIcon;
  const arrowIconImgAlt = item.icon_image && item.icon_image.alt_text;

  // Change Component tag name based on the condition
  const CustomTag =
    item.button_action?.hasActionableButton || !item.button_action ? "div" : "button";

  const modalLinksButtonAction = (url) => ({
    type: "IN_APP_BROWSER",
    url
  })

  const handleButtonClick = (button_action, title) => {
    if(button_action?.type === "CLOSE_ONBOARDING_MODAL") {
      sendAnalytics("Skip", pageName);
      addFlow("before");
      postOnboardingActions();
      return;
    }
    handleRedirect(button_action, history, title, pageName);
  }

  return (
    <>
      {item.button_multiple_external_links_page ? (
        <ModalWrapper fullscreen={false} classname="display-modal">
          <ul>
            {item.button_multiple_external_links_page.map((linkPage, i) => (
              <li key={i}>
                <span
                  onClick={() => {
                    handleButtonClick(
                      (item.button_action = modalLinksButtonAction(linkPage.link)),
                      item.title
                    );
                  }}
                >
                  {linkPage.link_label}
                </span>
              </li>
            ))}
          </ul>
        </ModalWrapper>
      ) : null}
      <CustomTag
        tabIndex="0"
        aria-label={item.titleAriaLabel}
        className={`pi2 ${handleOverrideClassName(item.classname)} ${
          handleOverrideConfig(theme.dropShadow, item.dropShadow) ? "show-shadow" : ""
        }`}
        {...(!item.button_action?.hasActionableButton && {
          onClick: () => handleButtonClick(item.button_action, item.title),
        })}
      >
        <div
          className="content-section"
          style={{
            cursor: `${handleOverrideConfig(theme.showIcon, item.showIcon) ? "pointer" : "unset"}`,
            borderRadius: `${!handleOverrideConfig(theme.showImage, item.showImage) && "8px"}`,
          }}
          // onClick={() => handleButtonClick(item.button_action, item.title)}
        >
          {/* Logo Image */}
          {handleOverrideConfig(theme.showLogoImage, item.showLogoImage) && item.logo_image && (
            <img
              className={`logo-img-icon ${handleOverrideClassName(item.logoImageClassname)}`}
              src={item.logo_image.path}
              alt={item.logo_image.alt_text}
            />
          )}
          <div className="content-text" aria-label={item?.titleAriaLabel}>
            {item.title && (
              <FormattedText
                ariaLabel={item.titleAriaLabel}
                className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}
                component="h3"
              >
                {item.title}
              </FormattedText>
            )}
            {/* Description */}
            <FormattedText
              ariaLabel={item.descriptionAriaLabel}
              className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}
              component="p"
            >
              {item.description}
            </FormattedText>

            {/* Description 2 */}
            <FormattedText
              ariaLabel={item.descriptionAriaLabel}
              className={`bodyText ${handleOverrideClassName(item.bodyText2Classname)}`}
              component="p"
            >
              {item.description2}
            </FormattedText>
          </div>

          {/* Arrow icon */}
          {handleOverrideConfig(theme.showIcon, item.showIcon) && (
            <img
              className={`icon-img ${handleOverrideClassName(item.iconClassname)}`}
              src={arrowIconImg}
              alt={arrowIconImgAlt}
            />
          )}

          {/* Action Button */}
          {item.button_action?.hasActionableButton && (
            <div className="button-wrapper">
              <Button
                buttonType={handleOverrideConfig(
                  theme.buttonType,
                  item.button_action?.button_settings?.buttonType
                )}
                buttonClassname={handleOverrideClassName(
                  item.button_action?.button_settings?.buttonClassname
                )}
                buttonText={item.button_action?.button_settings?.buttonText}
                handleRedirect={() => handleButtonClick(item.button_action, item.title)}
                showButtonIcon={true} //eslint-disable-line
                tabIndex={0}
              />
            </div>
          )}
        </div>

        {handleOverrideConfig(theme.showImage, item.showImage) && (
          <div
            aria-label={item?.title}
            className={`img-card ${handleOverrideClassName(item.imgClassname)}`}
            style={{ backgroundImage: item.image && item.image.path && `url(${item.image.path})` }}
          />
        )}
      </CustomTag>
    </>
  );
};

export default PI2;
