import { useReducer } from "react";

// Components

// Lib
import { useNavigate } from "react-router-dom";

// Localization
import { useTranslation } from "react-i18next";
import Interests from "../../../OnboardingModules/MTN/Components/Interests/Interests";

// Reducer
import { reducer } from "../../../OnboardingModules/MTN/Components/Interests/reducer";

// Service
import UserService from "../../../../services/UserService";

const EditInterests = () => {
  const [t] = useTranslation("translations");
  const history = useNavigate();

  const [{ interests }, dispatch] = useReducer(reducer, {
    interests: UserService.getAttribute("userPreferences").interests || [],
  });

  const handleOnSaveClick = () => {
    UserService.setAttribute("userPreferences", {
      ...UserService.getAttribute("userPreferences"),
      interests,
    });

    return history("/profile");
  };

  return (
    <div className="interestspage editpage">
      <div className="header">
        <h3>{t("ProfilePage.interests_page__title")}</h3>
      </div>

      <Interests interests={interests} dispatch={dispatch} />

      <footer>
        <button className="search-results-btn" onClick={() => handleOnSaveClick()}>
          {t("ProfilePage.interests_page__save_interests_button_text")}
        </button>
      </footer>
    </div>
  );
};

export default EditInterests;
