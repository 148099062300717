import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorPage, ErrorIcon, Title, Description, Button } from "./ErrorPage.wrapper";

const ErrorNoNetworkPage = () => {
  const [t] = useTranslation("translations");

  return (
    <ErrorPage>
      <ErrorIcon icon_type="network" />
      <Title>{t("ErrorPage.error_no_network_page__title")}</Title>
      <Description>{t("ErrorPage.error_no_network_page__description")}</Description>
      <Button>{t("Retry")}</Button>
    </ErrorPage>
  );
};

export default ErrorNoNetworkPage;
