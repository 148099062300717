import React from "react";

// Services
import i18n from "../../i18n";

// Images
import { ReactComponent as ArrowIcon } from "../../assets/icon_right_arrow.svg";
import { ReactComponent as StackedArrowIconRight } from "../../assets/icon_stacked_arrow_right.svg";
import { ReactComponent as StackedArrowIconLeft } from "../../assets/icon_stacked_arrow_left.svg";

const buttonClassnames = {
  TEXT: "text-button",
  OUTLINED: "outlined-button",
  FILL: "fill-button",
  STACKED: "stacked-button",
};

const Button = ({
  buttonType,
  buttonClassname,
  buttonText,
  handleRedirect,
  showButtonIcon,
  buttonIconImage,
  buttonIconPosition,
  buttonIconClassname,
}) => {
  const iconStyle = {
    marginLeft:
      buttonIconPosition === "Onboarding.Next" && buttonType !== "STACKED" ? 8 : undefined,
    marginRight:
      buttonIconPosition === "Onboarding.Back" && buttonType !== "STACKED" ? 8 : undefined,
    // transform: buttonIconPosition === "Onboarding.Back" ? "scaleX(-1)" : "scaleX(1)",
  };

  // Ensure buttonType is a string
  const isStacked = buttonType === "STACKED";
  const StackedArrow = () => {
    if (buttonIconPosition === "Onboarding.Next") {
      return (
        <StackedArrowIconRight className={`icon-img ${buttonIconClassname}`} style={iconStyle} />
      );
    }
    return <StackedArrowIconLeft className={`icon-img ${buttonIconClassname}`} style={iconStyle} />;
  };

  return (
    <button
      className={`action-button ${buttonClassnames[buttonType]} ${buttonClassname}`}
      onClick={handleRedirect}
      style={{ flexDirection: buttonIconPosition === "Onboarding.Back" ? "row-reverse" : "row" }}
      aria-label={buttonText}
    >
      {i18n.t(buttonText)}

      {/* Arrow Icon */}
      {showButtonIcon && (
        <>
          {buttonIconImage && buttonIconImage.path ? (
            <img
              className={`icon-img ${buttonIconClassname}`}
              src={buttonIconImage.path}
              alt={buttonIconImage.alt_text}
              style={iconStyle}
            />
          ) : (
            <>
              {isStacked ? (
                <StackedArrow />
              ) : (
                <ArrowIcon className={`icon-img ${buttonIconClassname}`} style={iconStyle} />
              )}
            </>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
