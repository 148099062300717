import * as React from "react";
import Dialog from "@mui/material/Dialog";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  fullscreen?: boolean;
  classname?: string;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, children, fullscreen = true, classname }) => (
  <Dialog
    fullScreen={fullscreen}
    className={classname}
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {children}
  </Dialog>
);

export default Modal;
