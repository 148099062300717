import { TFunctionResult } from "i18next";

interface Props {
  // eslint-disable-next-line no-undef
  children?: React.ReactNode & TFunctionResult;
}

export const ErrorPage = ({ children }: Props) => (
  <main className="errorpage">
    <div className="errorpage__container">{children}</div>
  </main>
);

interface IconProps extends Props {
  icon_type: "exclamation" | "network";
}

export const ErrorIcon = ({ icon_type }: IconProps) => (
  <div>
    <div className="errorpage__icon">
      <span className={`icon-${icon_type}`} />
    </div>
  </div>
);

export const Title = ({ children }: Props) => <h1>{children}</h1>;

export const Description = ({ children }: Props) => (
  <p className="errorpage__description">{children}</p>
);

// eslint-disable-next-line no-undef
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // eslint-disable-next-line no-undef
  children?: React.ReactNode & TFunctionResult;
  // eslint-disable-next-line no-undef
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({ children, onClick, ...rest }: ButtonProps) => {
  const isOnClickNull = onClick === undefined;

  const onClickFallback = () => {
    window.location.reload();
  };

  return (
    <button
      {...rest}
      onClick={isOnClickNull ? onClickFallback : onClick}
      className="errorpage__btn"
    >
      {children}
    </button>
  );
};
