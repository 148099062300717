import { useEffect, useState } from "react";
import { useApp } from "../../../context/AppContext";
import UserService from "../../../services/UserService";
import { CompletedFlow } from "../MTN/Types";
import parseNestedUrl from "../../../utils/parseNestedUrl";

export default function useAddCompletedOnboardingFlow() {
  const [flowName, setFlowName] = useState<string | null>(null);
  const [triggerEvent, setTriggerEvent] = useState<"before" | "after" | null>(null);
  const { configs } = useApp();

  useEffect(() => {
    if (!flowName || !triggerEvent) return;

    const addFlow = () => {
      const updatedFlowName = flowName === "/" ? "homepage" : flowName;

      const userPreferences = UserService.getAttribute("userPreferences");
      const completedFlows = userPreferences.completedFlows ?? [];
      const matchedCompletedFlow = completedFlows.find(
        ({ pageName }) => pageName === updatedFlowName
      );

      const config = configs.flowModule[triggerEvent].find(({ name }) => name === updatedFlowName);
      const beforeID = config?.before_id;
      const afterID = config?.after_id;

      // console.log("before", beforeID, "after", afterID);

      let flowObj: CompletedFlow = { pageName: updatedFlowName };
      if (triggerEvent === "before" && beforeID) {
        flowObj = { ...flowObj, before: true, before_id: beforeID };
      } else if (triggerEvent === "after" && afterID) {
        flowObj = { ...flowObj, after: true, after_id: afterID };
      }

      let updatedCompletedFlows: Array<CompletedFlow>;
      if (!matchedCompletedFlow) {
        updatedCompletedFlows = [...completedFlows, flowObj];
      } else {
        const index = completedFlows.indexOf(matchedCompletedFlow);
        completedFlows.splice(index, 1);
        updatedCompletedFlows = [...completedFlows, { ...matchedCompletedFlow, ...flowObj }];
      }

      UserService.setAttribute("userPreferences", {
        ...userPreferences,
        completedFlows: updatedCompletedFlows,
      });
    };

    addFlow();
  }, [flowName, triggerEvent, configs]);

  const addFlow = (event: "before" | "after") => {
    const { basePathname } = parseNestedUrl(window.location.pathname);
    setFlowName(basePathname);
    setTriggerEvent(event);
  };

  return addFlow;
}
