import { useTranslation } from "react-i18next";

const TopBar = ({ onSkip }: { onSkip?: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="onboarding__top_bar">
      {onSkip && (
        <button onClick={onSkip} className="onboarding__btn">
          {t("Onboarding.Skip")}
        </button>
      )}
    </div>
  );
};

export default TopBar;