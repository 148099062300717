import React from "react";

// Components
import Button from "../Reusable/Button";

// Utils
import {
    handleOverrideClassName,
    handleOverrideConfig,
    handleRedirect
  } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const FC4 = ({ 
    item,
    theme,
    history,
    pageName
}) => (
        <div 
            className={`fc4 ${handleOverrideClassName(item.classname)}`} 
            style={
                ({boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)", })
            }
        >   
        
            {
                (handleOverrideConfig(theme.showImage, item.showImage) && item.image)
                &&
                <img className={`img-icon ${handleOverrideClassName(item.iconClassname)}`} src={item.image.path} alt={item.image.alt_text} />
            }

            
            {/* Title text */}

            {
                item.title
                &&
                <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
            }

            {/* Body Text */}

            {
                item.description
                && 
                <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>{i18n.t(item.description)}</p>
            }

            <div className="button-wrapper">
                <Button 
                    buttonType={handleOverrideConfig(theme.buttonType, item.buttonType)}
                    buttonClassname={handleOverrideClassName(item.buttonClassname)}
                    buttonText={item.buttonText}
                    handleRedirect={() =>
                        handleRedirect(item.button_action, history, item.buttonText, pageName)}
                    showButtonIcon={item.showButtonIcon}
                    buttonIconImage={item.buttonIconImage}
                    buttonIconPosition={item.buttonIconPosition}
                    buttonIconClassname={handleOverrideClassName(item.buttonIconClassname)}
                />
            </div>
        </div>
    )

export default FC4;
