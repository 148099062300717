import { useEffect } from "react";

// Utils
import { handleAnalyticsTrack } from "../../utils/Analytics";
import PageContent from "../ListingPageContent";
import { getOnboardingPageName } from "../../utils/CommonFunctions";
import ModalOnboardingNavBars from "./ModalOnboardingNavBars";

interface IDynamicOnboardingPage {
  json: any;
  type: "page" | "modal";
  buttonStyle?: string;
}

const DynamicOnboardingPage = ({ json, type, buttonStyle }: IDynamicOnboardingPage) => {
  const PageBody = <PageContent json={json} theme={window.config.theme} pageName="onboarding" />;
  const locationPath = window.location.pathname;

  useEffect(() => {
    const pageName = getOnboardingPageName(locationPath);
    handleAnalyticsTrack(pageName, "onLoad_event", "page_view");
  }, [locationPath]);

  if (type === "modal") {
    return <ModalOnboardingNavBars buttonStyle={buttonStyle}>{PageBody}</ModalOnboardingNavBars>;
  }
  return PageBody;
};

export default DynamicOnboardingPage;
