import React from "react";
import { useModal } from "../../context/ModalContext";
import useAddCompletedOnboardingFlow from "./hooks/useAddCompletedOnboardingFlow";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";

interface OnboardingPageProps {
  children?: React.ReactNode;
  title?: string;
  onNext?: () => void;
  buttonStyle?: string;
}

const ModalOnboardingNavBars = ({ children, buttonStyle }: OnboardingPageProps) => {
  const addFlow = useAddCompletedOnboardingFlow();
  const {
    state: { totalSteps, currentStep },
    nextStep,
    prevStep,
    closeModal,
  } = useModal();

  const handleSkip = () => {
    addFlow("after");
    closeModal();
  };

  const handleNext = () => {
    nextStep();
  };

  const handleBack = () => {
    prevStep();
  };

  return (
    <div className="onboarding onboarding__modal">
      <TopBar onSkip={handleSkip} />
      <div className="onboarding__page">{children}</div>
      <BottomBar
        onNext={handleNext}
        stepProgress={currentStep}
        maxSteps={totalSteps}
        onBack={handleBack}
        buttonStyle={buttonStyle}
      />
    </div>
  );
};

export default ModalOnboardingNavBars;
